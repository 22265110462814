body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.minHeight500 {
  min-height: 500px !important;
}

.minHeightCard {
  min-height: 491px !important;
  /* overflow-y: auto; */
}

.eGEGHg {
  overflow-y: auto !important;
}

.breadcrumb li:not(.active){
  cursor: pointer;
}

.breadcrumb li {
  text-transform: capitalize;
}

.far {
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.nav-item {
 cursor: pointer; 
}

.panel-accordion {
 background-color: #F4F5FB; 
}

.error-template {padding: 40px 15px;text-align: center;}

.form-legend {
  margin: 20px;
  width: 155px;
}
.form-fieldset {
  border: 1px #C5C5C5 solid;
}

.rdtCounters > div {
    float: left;
    width: 43px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
}
